import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAccessToken } from "../Slices/userSlice";

const accessToken = localStorage.getItem("access_token");
const refreshToken = localStorage.getItem("refresh_token");

export const setAuthToken = (token) => {
  if (token === "delete") {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

// https://educify.org

const base_url = import.meta.env.VITE_BASEURL;

export const customFetch = axios.create({
  baseURL: `${base_url}/api/v1`,
});
export const wiseFetch = axios.create({
  baseURL: "https://api.sandbox.transferwise.tech/v1",
  headers: {
    "Accept-Minor-Version": 1,
  },
});

export const customFetchToken = axios.create({
  baseURL: `${base_url}/api/v1`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  },
});

export const handleUnauthorized = async (error, expire) => {
  try {
    const { data } = await axios.post(`${base_url}/api/v1/users/refreshToken`, {
      refreshToken: localStorage.getItem("refresh_token")
        ? localStorage.getItem("refresh_token")
        : refreshToken,
    });
    if (data.accessToken) {
      setAccessToken(data.accessToken);
      localStorage.setItem("access_token", data.accessToken);
      // localStorage.setItem("refresh_token", data.refreshToken);
      setAuthToken(data.accessToken);
      const originalRequest = error.config;
      originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
      return axios(originalRequest);
    } else {
      // Handle token refresh failure (e.g., logout)
      logout();
      return Promise.reject(error);
    }
  } catch (err) {
    if (err.message !== "Network Error") {
      if (
        (err?.response?.data?.message.includes("jwt") ||
          err?.response?.data?.message.includes("token") ||
          err?.response?.data?.message.includes("authorization") ||
          err?.response?.data?.message.includes("authorized")) &&
        err?.response?.data?.message !== "Refresh token is required"
      ) {
        logout();
        return Promise.reject(err);
      }
    }

    // return history.push("/login");
    // Handle error, for example by redirecting to login page
  }
  return Promise.reject(error);
};

customFetchToken.interceptors.response.use(
  (response) => response,
  handleUnauthorized
);
export const logout = () => {
  localStorage.clear();
  delete axios.defaults.headers.common["Authorization"];
  setAuthToken("delete");
  window.location.href = "#/login/sessionExpired";
};

export const customFetchWithCred = axios.create({
  baseURL: `${base_url}/api/v1`,
  withCredentials: true,
});
export default customFetch;
