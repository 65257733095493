import React, { useState } from "react";
import delBtn from "../../../assets/SVG/del-btn.svg";
import editBtn from "../../../assets/SVG/edit-btn.svg";
import arrRight from "../../../assets/SVG/arrRight.svg";
import Button from "../../../Components/Button/Button";
import closeBtn from "/src/assets/AdminApp/svgs/close.svg";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleSliceChange } from "../../../ReduxToolkit/Slices/teacherSlice";
import { Tooltip } from "@mui/material";
import SharedSingleImageUploader from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";

const SignupStep12 = () => {
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [certifications, setCertifications] = useState(
    teacherToRegister?.certificates ? teacherToRegister.certificates : []
  );
  const [image, setImage] = useState();
  const [certification, setCertification] = useState({
    name: "",
    organization: "",
    startDate: "",
    endDate: "",
    credentialId: "",
    credentialUrl: "",
    image: { url: "", id: "", publicId: "" },
  });

  const handleCertificationChange = ({ name, value }) => {
    setCertification({ ...certification, [name]: value });
  };
  const handleCloseModal = () => {
    setCertification({
      name: "",
      organization: "",
      startDate: "",
      endDate: "",
      credentialId: "",
      credentialUrl: "",
      image: { url: "", id: "", publicId: "" },
    });
    setShow(false);
  };
  const handleAddCertificate = () => {
    if (!validateCertification()) return;

    if (editIndex !== null) {
      // Edit mode
      const updatedCertifications = certifications.map((exp, index) =>
        index === editIndex ? certification : exp
      );
      setCertifications(updatedCertifications);
    } else {
      // Add mode
      setCertifications([...certifications, certification]);
    }

    // Reset the image state after adding or editing the certification
    setImage(null);

    // Close the modal and reset the certification state
    handleCloseModal();
  };

  const handleDeleteCertification = (index) => {
    const updatedCertifications = certifications.filter((_, i) => i !== index);
    setCertifications(updatedCertifications);
  };

  const handleEditCertification = (index) => {
    const certificationToEdit = certifications[index];
    setCertification(certificationToEdit); // Set the certification data to be edited
    setImage(certificationToEdit.image); // Set the image for editing
    setShow(true); // Open the modal to edit the experience
    setEditIndex(index); // Set the index of the experience being edited
  };

  const validateCertification = () => {
    const {
      name,
      organization,
      startDate,
      endDate,
      credentialId,
      credentialUrl,
    } = certification;
    const missingFields = [];

    if (!name) missingFields.push("name");
    if (!organization) missingFields.push("organization");
    if (!startDate) missingFields.push("Start Date");
    if (!endDate) missingFields.push("End Date");
    if (!credentialId) missingFields.push("credential Id");
    if (!credentialUrl) missingFields.push("credentialUrl");
    if (!image?.url) missingFields.push("image");

    if (missingFields.length > 0) {
      toast.warning(
        `Please fill out the following fields: ${missingFields.join(", ")}`
      );
      return false;
    }
    return true;
  };
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (!certifications?.length) {
      return toast.error("Empty certifications");
    }
    navigate("/teacher-signup/step-twelve");
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: {
          ...teacherToRegister,
          certificates: certifications,
        },
      })
    );
  };
  const onSelectFile = (imageData) => {
    setImage(imageData);
    setCertification({ ...certification, image: imageData });
  };
  return (
    <div className="signup_experience_section_container">
      <div className="signup_experience_section">
        <div className="title">
          <h1>Certification</h1>
        </div>
        <div className="experience_container">
          <p className="text_description">
            Display your professional credentials. Highlight your certifications
            and specializations to demonstrate your qualifications and
            dedication to your field
          </p>
          <div className="exp_list">
            {certifications &&
              certifications?.map((el, index) => (
                <CertificateItem
                  key={index}
                  {...el}
                  image={el?.image?.url}
                  index={index}
                  onDelete={handleDeleteCertification}
                  onEdit={handleEditCertification}
                />
              ))}
          </div>
          <div className="add_experience_button">
            <span className="xp" onClick={() => setShow(true)} role="button">
              Add {!!certifications?.length && "another"} Certification
              <span className="plus">+</span>
            </span>
          </div>

          <div className="button_exp">
            <Button
              variant={"cancel"}
              onClick={() => navigate("/teacher-signup/step-ten")}
            >
              Go back
            </Button>
            <Button variant="new_secondary" onClick={handleSubmit}>
              Next
              <img src={arrRight} alt="" />
            </Button>
          </div>
        </div>
        {show && (
          <CertificationModal
            certification={certification}
            handleCertificationChange={handleCertificationChange}
            handleAddCertificate={handleAddCertificate}
            handleCloseModal={handleCloseModal}
            image={image}
            setImage={setImage}
            onSelectFile={onSelectFile}
          />
        )}
      </div>
    </div>
  );
};

export const CertificationModal = ({
  certification,
  handleCertificationChange,
  handleAddCertificate,
  handleCloseModal,
  image,
  setImage,
  onSelectFile,
}) => {
  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)

  return (
    <div className="xp_modal_overlay">
      <div className="xp_modal_container">
        <img
          src={closeBtn}
          className="close"
          alt=""
          onClick={handleCloseModal}
        />
        <div className="header">
          <h1>Add Certification</h1>
        </div>
        <div className="inputs">
          <div className="input">
            <span>Certificate Name</span>
            <input
              type="text"
              placeholder="Certificate Name"
              name="name"
              value={certification.name}
              onChange={(e) =>
                handleCertificationChange({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="input">
            <span>Issuing organization</span>
            <input
              type="text"
              placeholder="Issuing organization"
              name="organization"
              value={certification.organization}
              onChange={(e) =>
                handleCertificationChange({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </div>

          <div className="multiple">
            <div className="input">
              <span>Start date</span>
              <input
                type="date"
                name="startDate"
                value={certification.startDate}
                onChange={(e) =>
                  handleCertificationChange({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="input">
              <span>End date</span>
              <input
                type="date"
                name="endDate"
                value={certification.endDate}
                onChange={(e) =>
                  handleCertificationChange({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="input">
            <span>Credential ID</span>
            <input
              type="text"
              placeholder="Credential ID"
              name="credentialId"
              value={certification.credentialId}
              onChange={(e) =>
                handleCertificationChange({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="input">
            <span>Credential URL</span>
            <input
              type="text"
              placeholder="Credential URL"
              name="credentialUrl"
              value={certification.credentialUrl}
              onChange={(e) =>
                handleCertificationChange({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="input with_image">
            <span>Image</span>
            <SharedSingleImageUploader
              onSelectFile={onSelectFile}
              detectFace={false}
              preview={image}
              MAX_SIZE={MAX_SIZE}
            />
          </div>
          <div className="modal_submit">
            <Button variant="new_secondary" onClick={handleAddCertificate}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CertificateItem = ({
  name,
  organization,
  startDate,
  endDate,
  credentialId,
  credentialUrl,
  image,
  onDelete,
  onEdit,
  index,
  isAdmin,
  className,
}) => {
  return (
    <div className={`signup_certificate_item ${className ? className : ""}`}>
      <div className="col">
        <div className="cert_title">
          <div className="cert_logo">
            <Tooltip title="Click to preview" arrow placement="top">
              <img
                onClick={() => isAdmin && window.open(image, "_blank")}
                src={image}
                alt=""
              />
            </Tooltip>
          </div>
        </div>
        <div className="cert_name">
          <h4>{name}</h4>
          <span>by {organization}</span>
          <p>Issued {moment(startDate).format("MMM YYYY")}</p>
          {!!isAdmin && (
            <>
              <p>
                credentialUrl :{" "}
                <a href={credentialUrl} target="_blank">
                  {credentialUrl}
                </a>
              </p>
              <p>End Date : {moment(endDate).format("DD/MM/YYYY")}</p>
              <p>credential ID: {credentialId}</p>
            </>
          )}
        </div>
      </div>
      {!!!isAdmin && (
        <div className="col">
          <img src={editBtn} alt="" onClick={() => onEdit(index)} />
          <img src={delBtn} alt="" onClick={() => onDelete(index)} />
        </div>
      )}
    </div>
  );
};
export default SignupStep12;
