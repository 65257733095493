import { Tooltip } from "@mui/material";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import * as faceapi from "face-api.js";

import volicity from "../../assets/TeacherApp/assets/svgs/volicity.svg";
import example_img from "../../assets/HomeApp/images/tutor.webp";
import remove from "../../assets/SharedApp/svgs/remove.svg";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { uploadImage } from "../../ReduxToolkit/Slices/teacherSlice";
import { deleteImage } from "../../ReduxToolkit/Slices/userSlice";
import "./styles.scss";
import Webcam from "react-webcam";
import Note from "../../TeacherApp/Components/Note/Note";

import eximg1 from "../../assets/AdminApp/images/eximg1.webp";
import eximg2 from "../../assets/AdminApp/images/eximg2.webp";
import eximg3 from "../../assets/AdminApp/images/eximg3.webp";
import eximg4 from "../../assets/AdminApp/images/eximg4.webp";
import tick from "../../assets/AdminApp/svgs/tick.svg";
import x from "../../assets/AdminApp/svgs/x.svg";
import smile from "../../assets/AdminApp/svgs/smile.svg";

export default function SharedSingleImageUploader({
  preview,
  onSelectFile,
  example,
  tip,
  MAX_SIZE,
  detectFace,
}) {
  const imgRef = useRef(null);
  const dispatch = useDispatch();

  const deleteImages = () => {
    preview && dispatch(deleteImage(preview.publicId));
    toast.warning(
      `Add an image of size 4mb`
    );

    onSelectFile({
      url: "",
      publicId: "",
    });
  };

  const sendToast = (m) => toast.error(m);

  const [imageLoading, setImageLoading] = useState(false);
  
  const haveFace = async (file) => {
    let res = await FaceDetectionComponent(file);
    return res;
  };

  const handleChange = async (e) => {
    let hasFace;
    setShowModal(false);
    setImageLoading(true);

    const file = e?.target?.files[0];
    if (!file) {
      if (detectFace) {
        let face = await haveFace(e);
        if (!face) {
          setImageLoading(false);
          return toast.warning(
            <div>
              <p>
                Oops! It seems there's no face detected in your profile picture.
              </p>
              <span>
                A friendly face adds a personal touch – please add a new photo
                to enhance connection with your students!
              </span>
            </div>,
            { autoClose: 7000 }
          );
        }
        if (face) {
          setImageLoading(true);
          handleImageUpload(e);
        }
      } else {
        if (!detectFace) {
          setImageLoading(true);
          handleImageUpload(e);
        }
      }
    } else {
      try {
        if (detectFace) {
          hasFace = await FaceDetectionComponent(file);
        }
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            if (file.size > MAX_SIZE) {   
              // Alert user that the image is too large
              sendToast(
                `Image size must be within ${MAX_SIZE / (1024 * 1024)} MB.`
              );
              setImageLoading(false);
              return;
            }
            if (hasFace || !detectFace) {
              // setImageLoading(true);
              handleImageUpload(file);
              const objectUrl = URL.createObjectURL(file);
              return () => URL.revokeObjectURL(objectUrl);
            } else {
              setImageLoading(false);
              if (detectFace) {
                return toast.warning(
                  <div>
                    <p>
                      Oops! It seems there's no face detected in your profile
                      picture.
                    </p>
                    <span>
                      A friendly face adds a personal touch - please add a new
                      photo to enhance connection with your students!
                    </span>
                  </div>,
                  { autoClose: 7000 }
                );
              }
            }
          };
          img.src = event.target.result;
        };
        reader.readAsDataURL(file);
      } catch (err) {
        toast.warning(
          <div>
            <p>
              Oops! It seems there's no face detected in your profile picture.
            </p>
            <span>
              A friendly face adds a personal touch – please add a new photo to
              enhance connection with your students!
            </span>
          </div>,
          { autoClose: 7000 }
        );
        return setImageLoading(false);
      }
    }
  };
  const handleImageUpload = (file) => {
    let form = new FormData();
    form.append("file", file);
    // FaceDetectionComponent()
    Promise.resolve(dispatch(uploadImage(form)))
      .then((value) => {
        if (!value.type === "uploadImage/fulfilled") {
          setImageLoading(false);
          return sendToast("Error uploading image, please try again");
        }
        setImageLoading(false);
        onSelectFile(value.payload);
      })
      .catch((err) => console.log("Error up", err));
  };
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="image__selector">
        <div className="upload">
          <div className="image">
            <Tooltip
              title={`Image size must be  less than ${
                MAX_SIZE / (1024 * 1024)
              } MB.`}
              arrow
              placement="top"
            >
              <div className="image-upload ">
                <label
                  // htmlFor="file-input-2"
                  ref={imgRef}
                >
                  {imageLoading ? (
                    <div className="spinner" />
                  ) : !preview?.url ? (
                    <img
                      src={volicity}
                      alt=""
                      className="img__btn"
                      onClick={() => setShowModal(true)}
                    />
                  ) : (
                    <img src={preview?.url} alt="" className="coverImage" />
                  )}
                </label>

                {/* <input id="file-input-2" type="file" accept="image/*" /> */}
                {/* <input
                  type="file"
                  id="file-input-2"
                  onChange={(e) => handleChange(e)}
                /> */}
              </div>
            </Tooltip>
            <Tooltip
             title={`add and image of size ${
              MAX_SIZE / (1024 * 1024)
            } MB.`}
            arrow
            placement="top"
            >
            <img
              src={remove}
              alt=""
              className="remove action"
              onClick={() => deleteImages()}
            />

            </Tooltip>
       
            {/* <img
            src={edit}
            alt=""
            className="edit action"
            onClick={() => imgRef.current.click()}
          /> */}
          </div>
          {!example && (
            <span className="left small_grey">
              Allowed file types: webp, jpg, jpeg.
            </span>
          )}
        </div>
        {example && (
          <div className="note_container_2">{/* <GoodToKnow /> */}</div>
        )}
      </div>

      {showModal && (
        <UploadModal handleChange={handleChange} setShowModal={setShowModal} />
      )}
    </>
  );
}

const FaceDetectionComponent = async (file) => {
  try {
    const img = await faceapi.bufferToImage(file);

    // Load face-api.js models
    await faceapi.nets.tinyFaceDetector.loadFromUri("/models/faceapi");
    await faceapi.nets.faceLandmark68Net.loadFromUri("/models/faceapi");
    await faceapi.nets.faceRecognitionNet.loadFromUri("/models/faceapi");

    // Detect faces in the image
    const detections = await faceapi
      .detectAllFaces(img, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceDescriptors();

    const hasDetectedFace = detections.length > 0;
    return hasDetectedFace;
    // Update state based on face detection result
  } catch (err) {
    return;
  }
};

const UploadModal = ({ setShowModal, handleChange }) => {
  const inptRef = useRef(null);
  const [showImage, setShowImage] = useState(false);
  const [capImage, setCapImage] = useState("");

  const base64ToFile = (base64Image, fileName) => {
    const base64Data = base64Image.split(",")[1]; // Remove header data
    const type = base64Image.split(",")[0].split(":")[1].split(";")[0]; // Get image type
    const bytes = atob(base64Data); // Convert base64 to binary
    const buffer = new ArrayBuffer(bytes.length);
    const array = new Uint8Array(buffer);
    for (let i = 0; i < bytes.length; i++) {
      array[i] = bytes.charCodeAt(i);
    }
    const blob = new Blob([buffer], { type });
    const file = new File([blob], fileName);
    return file;
  };
  const dispatch = useDispatch();
  const handleImageUpload = (file) => {
    handleChange(file);
  };
  useEffect(() => {
    if (capImage) {
      let res = base64ToFile(capImage, "adiz.jpg");
      handleImageUpload(res);
    }
  }, [capImage]);

  return (
    <div className="modal_wrapper">
      <div className="upload_modal">
        {/* <h2>Choose an upload method </h2> */}
        <Note
          p1={"1. Upload: Choose an image from your device."}
          p2={"2. Take a picture: take an image directly from your device."}
          p3={
            "3. Capture photo: Once ready, hit the 'Capture photo' button to share your image."
          }
        />
        <h2>Welcome to our Image Uploader! 📸</h2>
        {showImage && (
          <WebcamCapture setImgSrc={setCapImage} setShowImage={setShowImage} />
        )}
        {!showImage && capImage && <img src={capImage} />}
        <div className="modal_buttons">
          <button
            className="upload_btn"
            onClick={() => {
              inptRef.current.click();
            }}
          >
            <span>Upload File</span>
            <input
              type="file"
              ref={inptRef}
              onChange={(e) => handleChange(e)}
              className="input_file_btn"
              accept="image/*"
            />
          </button>
          {!showImage && (
            <button className="upload_btn" onClick={() => setShowImage(true)}>
              Take a picture
            </button>
          )}
          <button onClick={() => setShowModal(false)} className="cancel_btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
const WebcamCapture = ({ setImgSrc, setShowImage }) => {
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setShowImage(false);
  }, [webcamRef, setImgSrc]);

  return (
    <div className="webcam_container">
      <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
      <button onClick={capture}>Capture photo</button>
      <span>or</span>
    </div>
  );
};

export const GoodToKnow = ({
  notes = [
    "Show yourself",
    "Face front for the camera",
    "Avoid sunglasses, logos, blurry, pixelated or too-dark photos",
    "Min. 600 X 600px, .PNG or .JPG",
  ],
  title = "Attention!",
  images = [eximg4, eximg1, eximg2, eximg3],
  className,
  subtitle,
}) => {
  return (
    <div className={`good_to_know_notes ${className ?? ""}`}>
      <div className="title">
        <div className="icon">
          <img src={smile} alt="" />
        </div>
        <h1>{title}</h1>
      </div>
      <div className="list">
        {subtitle && <p>{subtitle}</p>}
        <ul>
          {notes.map((el, i) => (
            <li key={i}>{el}</li>
          ))}
        </ul>
      </div>
      <div className="ex_images">
        {images.map((img, i) => (
          <div className="ex_image" key={i}>
            <img src={img} alt="example" />
            <div className={`tick ${i === 0 || i === 1 ? "danger" : ""}`}>
              <img src={i === 0 || i === 1 ? x : tick} alt=""/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
