import React, { useState } from "react";
import "./PageDescription.styles.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import check from "../../../assets/HomeApp/svgs/check_circled.svg";
import Equalizer from "../../../assets/HomeApp/svgs/Equalizer.svg";
import gen014 from "../../../assets/HomeApp/svgs/gen014.svg";
import medal from "../../../assets/HomeApp/svgs/medal.svg";
import Mirror from "../../../assets/HomeApp/svgs/Mirror.svg";
import profile_circled from "../../../assets/HomeApp/svgs/profile_circled.svg";
import defaultImage from "../../../assets/SharedApp/images/default.webp";
import moment from "moment";

export default function PageDescription({
  teacher,
  img,
  description,
  name,
  level,
}) {
  const [showFullText, setShowFullText] = useState(false);
  function limitWords(text, limit) {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  }

  const LangsFormatted = teacher.languages
    .map((lang) => lang.name.toLowerCase())
    .sort()
    .join(", ");
  return (
    <div className="tutor__page__description">
      <h4>Description</h4>
      <div className="description">
        <div className="header">
          <img src={img} alt="" />
          <div className="name">
            <span>{name}</span>
            <span className="type">Teacher</span>
          </div>
        </div>
        <h3 style={{ color: "#3f4254" }}>{teacher.title}</h3>

        <div className="description__text" id="description">
          <span>{description}</span>
          {/* {!showFullText ? (
            <span className="showMore" onClick={() => setShowFullText(true)}>
              View more <ExpandMoreIcon />
            </span>
          ) : (
            <span className="showMore" onClick={() => setShowFullText(false)}>
              View less
              <ExpandLessIcon />
            </span>
          )} */}
        </div>

        <div className="teacher__skils">
          {/* <div className="skill">
            <img src={Equalizer} alt="" />{" "}
            <span>
              <span style={{ textTransform: "capitalize" }}>
                Teaches {level}
              </span>
            </span>
          </div> */}
          <div className="skill">
            <img src={gen014} alt="" />{" "}
            <span>
              Teacher since {moment(teacher?.createdAt).format("DD-MM-YYYY")}
            </span>
          </div>
          {!!LangsFormatted.length && (
            <div className="skill">
              <img src={medal} alt="" />
              <span style={{ textTransform: "capitalize" }}>
                Speaks {LangsFormatted}
              </span>
            </div>
          )}
          {teacher?.background?.status !== "UNCHECKED" && (
            <div className="skill">
              <img src={medal} alt="" />
              <span style={{ textTransform: "capitalize" }}>
                Background Checked
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
