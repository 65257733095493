import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { Tooltip } from "@mui/material";

import {
  MinChatUiProvider,
  MainContainer,
  MessageInput,
  MessageContainer,
  MessageList,
  MessageHeader,
} from "@minchat/react-chat-ui";
import notificationSound from "./notify.wav";
import "./Chat.styles.scss"; // Import your CSS file for custom styles
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import defaultPP from "../../../assets/SharedApp/images/default.webp";
import {
  getContacts,
  getUserChatMessages,
} from "../../../ReduxToolkit/Slices/userSlice";
import ContactsContainer from "./ContactsContainer";
import moment from "moment";
import {
  uploadImage,
  uploadVideo,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import { toast } from "react-toastify";

const socketInitial = io(import.meta.env.VITE_BASEURL2, {
  auth: {
    token: localStorage.getItem("access_token"),
  },
  reconnection: true, // Ensure reconnection is enabled
  reconnectionAttempts: 5, // Try to reconnect 5 times before giving up
  reconnectionDelay: 1000, // Wait 1 second before trying to reconnect
});

const Chat = () => {
  const [socket, setSocket] = useState(socketInitial);
  const sendToast = toast.error;
  const { accessToken } = useSelector((state) => state.user);
  useEffect(() => {
    setSocket(
      io(import.meta.env.VITE_BASEURL2, {
        auth: {
          token: accessToken,
        },
        reconnection: true, // Ensure reconnection is enabled
        reconnectionAttempts: 5, // Try to reconnect 5 times before giving up
        reconnectionDelay: 1000, // Wait 1 second before trying to reconnect
      })
    );
  }, [accessToken]);
  const [messages, setMessages] = useState([]);

  const [input, setInput] = useState("");
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState();
  const [fileName, setFileName] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [userStatus, setUserStatus] = useState({});
  const [newMessage, setNewMessage] = useState(null);
  const [recipient, setRecipient] = useState({ name: "", profileImage: "" });
  const [recipientId, setRecipientId] = useState();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContacts()).then((res) => {
      if (res.type.includes("fulfilled")) {
        setUsers(res.payload);
        if (res.payload.length > 0)
          setRecipient({
            name: `${res?.payload[0]?.name} ${res?.payload[0]?.lastName}`,
            profileImage: res?.payload[0]?.profileImage?.url,
          });
        else {
          setRecipient({
            name: `Tutor`,
            profileImage: undefined,
            role: "User",
          });
        }
        setRecipientId(res.payload[0]?.id);
      }
    });
  }, [search]);
  const markMessageAsSeen = () => {
    socket.emit("message seen", {
      userId: user.id,
      recipientId,
    });
  };

  useEffect(() => {
    recipientId &&
      dispatch(getUserChatMessages(recipientId)).then((res) => {
        setMessages(res.payload.messages);
        setRecipient({
          profileImage: res?.payload?.profileImage,
          name: res?.payload?.name,
          role: res?.payload?.role,
        });
        markMessageAsSeen();
      });
  }, [recipientId, dispatch]);

  const audioRef = useRef(new Audio(notificationSound));

  useEffect(() => {
    // Join the shared room for the conversation
    if (user.id && recipientId) {
      socket.emit("join", user.id, recipientId);
    }
    // Listen for successful connection
    socket.on("connect", () => {
      console.log("Socket successfully connected with id:", socket.id);
    });

    // Listen for connection errors
    socket.on("connect_error", (error) => {
      console.log("Socket connection failed:", error.message);
    });

    // Listen for disconnection
    socket.on("disconnect", (reason) => {
      console.log("Socket disconnected:", reason);
    });
    socket.on("chat message", (msg) => {
      setMessages((prevMessages) => {
        // Prevent duplicate messages by checking if the message already exists
        if (!prevMessages.find((m) => m.id === msg.id)) {
          if (msg.senderId !== user.id) {
            audioRef.current.play();
            document.title = `New message from ${msg.name}`;
          }
          return [...prevMessages, msg];
        }
        return prevMessages;
      });
    });

    socket.on("typing", (userId, typingStatus) => {
      if (userId !== user.id) {
        setIsTyping(typingStatus);
      }
    });

    socket.on("message seen", (messageId) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId ? { ...msg, seen: true } : msg
        )
      );
    });

    socket.on("user status", ({ userId, status }) => {
      setUserStatus((prevStatus) => ({
        ...prevStatus,
        [userId]: status,
      }));
    });

    return () => {
      socket.off("chat message");
      socket.off("typing");
      socket.off("message seen");
      socket.off("user status");
    };
  }, [user?.id, recipientId]);

  const sendMessage = (content) => {
    if (content.trim() && recipientId) {
      const message = {
        content,
        recipientId,
        profileImage: user.profileImage?.url,
      };
      socket.emit("chat message", message);
      setInput("");
    }
  };

  const handleTypingEnd = () => {
    socket.emit("typing", recipientId, false);
  };

  const handleTypingStart = () => {
    socket.emit("typing", recipientId, true);
  };

  const handleSend = (message) => {
    console.log("message to send", message);
    sendMessage(message);
    handleTypingEnd();
  };
  const messageListRef = useRef(null);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo(0, messageListRef.current.scrollHeight);
    }
  };

  const [imageLoading, setImageLoading] = useState(false);
  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      sendToast("Please select a file");
      return;
    }
    if (file.size > MAX_SIZE) {
      sendToast(`File size must be within ${MAX_SIZE / (1024 * 1024)} MB.`);
      return;
    }

    setImageLoading(true);
    handleImageUpload(file);
    setAttachments("");
  };
  const handleImageUpload = (file) => {
    let form = new FormData();
    form.append("file", file);
    const fileType = file.type;
    let fileCategory;
    if (fileType.startsWith("image/")) {
      fileCategory = "image";
    } else if (fileType.startsWith("video/")) {
      fileCategory = "video";
    } else if (fileType === "application/pdf") {
      fileCategory = "pdf";
    } else {
      setImageLoading(false);
      return toast.error("Unsupported file type");
    }
    if (fileCategory === "video") {
      const video = document.createElement("video");
      video.preload = "metadata";
      console.log("reached here1");
      video.src = URL.createObjectURL(file);
      console.log("reached here2");

      video.onloadedmetadata = () => {
        Promise.resolve(dispatch(uploadVideo(form))).then((value) => {
          if (!value.type === "uploadVideo/fulfilled") {
            return sendToast("Error uploading video, please try again");
          }
          console.log("val");
          sendFile(value.payload.url, value.payload.fileName, fileCategory);
        });
      };
    } else
      Promise.resolve(dispatch(uploadImage(form))).then((value) => {
        if (!value.type === "uploadImage/fulfilled") {
          setImageLoading(false);
          return sendToast("Error uploading image, please try again");
        }
        setImageLoading(false);
        if (value.type !== "uploadImage/fulfilled") {
          return sendToast("Error uploading file");
        }
        setFileName(value.payload.fileName);
        setAttachments(value.payload.url);
        sendFile(value.payload.url, value.payload.fileName, fileCategory);
      });
  };
  const sendFile = (content, fileName, type) => {
    console.log("fileName here", fileName);
    if (content.trim() && recipientId) {
      const message = {
        content,
        recipientId,
        type,
        fileName,
        profileImage: user.profileImage?.url,
      };
      socket.emit("chat message", message);
      setInput("");
    }
  };
  const onCancelAttach = () => {
    setAttachments("");
    setFileName("");
  };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);

  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);
  const handleAttachClick = () => {
    // Trigger file selection
    document.getElementById("fileInput")?.click();
  };
  return (
    <div className="chat_page">
      <input
        id="fileInput"
        type="file"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      <div className="chat_layout">
        <div className="all_contacts_container">
          <ContactsContainer
            setSearch={setSearch}
            contacts={users}
            messageIndex={recipientId}
            profilePic={defaultPP}
            setMesageIndex={(id) => setRecipientId(id)}
          />
        </div>
        <div className="chatting_container">
          <MinChatUiProvider theme="#235987">
            <MainContainer
            // style={{ height: "60vh", background: "white", width: "600px" }}
            >
              <MessageContainer>
                <div className="message_header">
                  <img
                    src={
                      recipient?.profileImage
                        ? recipient?.profileImage
                        : defaultPP
                    }
                    alt=""
                    className="avatar"
                  />
                  <div className="name">
                    <span>{recipient.name ?? "Tutor"}</span>
                    <p style={{ textTransform: "capitalize" }}>
                      {recipient?.role?.toLowerCase()}
                    </p>
                  </div>
                </div>
                <CustomMessageList
                  messages={messages}
                  userId={user.id}
                  userProfile={user?.profileImage?.url}
                  userStatus={userStatus}
                  isTyping={isTyping}
                />

                <MessageInput
                  placeholder="Type message here"
                  onSendMessage={handleSend} // Directly pass handleSend
                  showSendButton
                  onStartTyping={handleTypingStart}
                  onEndTyping={handleTypingEnd}
                  onAttachClick={handleAttachClick}
                />
              </MessageContainer>
            </MainContainer>
          </MinChatUiProvider>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default Chat;
const CustomMessageList = ({
  messages,
  userId,
  userStatus,
  isTyping,
  userProfile,
}) => {
  const messageListRef = useRef(null);
  const scrollToBottom = () => {
    if (messageListRef?.current) {
      messageListRef.current.scrollTop = messageListRef?.current?.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  // const getStatusIndicator = (status: string) => {
  //   switch (status) {
  //     case "online":
  //       return <span className="status-indicator online"></span>;
  //     case "away":
  //       return <span className="status-indicator away"></span>;
  //     default:
  //       return <span className="status-indicator offline"></span>;
  //   }
  // };
  const { timezone } = useSelector((state) => state.user);

  function convertUtcToTimezone(utcDateString, timezone) {
    // Parse the UTC date string
    const utcDate = moment.utc(utcDateString);

    // Convert the UTC date to the specified timezone
    const localDate = utcDate.tz(timezone);

    // Format the date string as desired
    return localDate.format("MMMM Do YYYY, h:mm A");
  }
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();
  return (
    <div
      ref={messageListRef}
      style={{ overflowY: "auto", flexGrow: 1 }}
      className="chat-container"
    >
      <div className="all_user_messages">
        {messages &&
          messages.map((msg, i) => {
            const isOutgoing = msg.senderId === userId;
            let booking = msg.type === "booking" && user.role === "TEACHER";
            console.log("msg", msg);
            return (
              <Tooltip
                title={booking ? "Click to view request" : ""}
                arrow={booking}
              >
                <div
                  key={i}
                  onClick={() => {
                    if (booking) {
                      navigate("/teachers/myrequests");
                    }
                  }}
                  style={{
                    background:
                      msg.content ===
                      "Sharing contact information is not allowed. If this was repeated, we will suspend your account"
                        ? "red"
                        : "",
                    cursor: booking ? "pointer" : "inherit",
                  }}
                  className={`message-item ${
                    isOutgoing ? "outgoing" : "incoming"
                  }`}
                >
                  <div className="custom_message_header">
                    <div className="image_name">
                      <img
                        src={
                          // isOutgoing
                          //   ? msg?.sender?.profileImage?.url
                          //   : msg?.recipient?.profileImage?.url
                          //   ? msg?.recipient?.profileImage?.url

                          //     :

                          //     defaultPP
                          msg.profileImage
                        }
                        alt=""
                      />
                      <span>{msg?.senderName}</span>
                    </div>
                    <span className="msg_time">
                      {convertUtcToTimezone(msg.createdAt, timezone)}
                    </span>
                  </div>
                  {/* <img src={userInfo.avatar} alt="" className="avatar" /> */}
                  <div className="message-content">
                    {/* <div className="message-header">
                    <span className="username">{userInfo.name}</span>
                    {getStatusIndicator(userStatus[msg.senderId] || "offline")}
                  </div> */}
                    <p
                      className="message-text"
                      onClick={() => {
                        msg.type !== "message" &&
                          msg.type !== "booking" &&
                          window.open(msg.content);
                      }}
                    >
                      {msg.type === "message" || msg.type === "booking" ? (
                        msg.content
                      ) : (
                        <Tooltip title="Click to view">
                          <span>
                            {msg.type === "image" && (
                              <img
                                alt=""
                                src={msg.content}
                                className="msg_image"
                              />
                            )}
                            {msg.type === "video" && (
                              <video
                                controls
                                src={msg.content}
                                alt={msg.fileName}
                                style={{ maxWidth: "100%", height: "200px" }}
                              >
                                Your browser does not support the video tag.
                              </video>
                            )}
                            {msg.fileName}
                          </span>
                        </Tooltip>
                      )}
                    </p>

                    <div className="message-meta">
                      <span className="timestamp">
                        {/* {new Date(msg.createdAt).toLocaleTimeString()} */}
                      </span>
                      {/* {msg.seen && <span className="seen-indicator">Seen</span>} */}
                    </div>
                  </div>
                  {/* {msg.seen ? "seen" : "unseen"} */}
                </div>
              </Tooltip>
            );
          })}
      </div>

      {/* {isTyping && <span>...typing</span>} */}
    </div>
  );
};
