import React, { useEffect } from "react";
import "./Footer.styles.scss";
import logo from "../../assets/HomeApp/images/logo.webp";
import reboost from "../../assets/SharedApp/images/reboost-light.png";
import phone from "../../assets/HomeApp/svgs/phone.svg";
import location from "../../assets/HomeApp/svgs/location.svg";
import mail from "../../assets/HomeApp/svgs/mail.svg";
import uk from "../../assets/HomeApp/svgs/england.svg";
import usa from "../../assets/HomeApp/svgs/usa.svg";

import { socials } from "../../assets/HomeApp/data/footer";
import { contact } from "../../assets/HomeApp/data/footer";
import { footerCols } from "../../assets/HomeApp/data/footer";
import { text } from "../../assets/HomeApp/data/footer";

import Select from "react-select";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency } from "../../ReduxToolkit/Slices/currencySlice";

function Footer() {
  const { showCourses } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { currency } = useSelector((state) => state.currency);

  const handleCurrencyChange = (event) => {
    dispatch(setCurrency(event.target.value));
  };
  return (
    <footer>
      <div className="footer__container">
        <div className="footer__logo">
          <img src={logo} alt="" />
          <p>{text.text}</p>
        </div>
        <div className="columns">
          {footerCols.map((col, i) => (
            <div className="col" key={i}>
              <h4>{col.name}</h4>
              <ul>
                {showCourses
                  ? col.links.map((link, j) => (
                      <li key={j}>
                        <a target="_blank" href={col.href[j]}>
                          {link}
                        </a>
                      </li>
                    ))
                  : col.links
                      .filter((lnk) => !lnk.toLowerCase().includes("courses"))
                      .map((link, j) => (
                        <li key={j}>
                          <a target="_blank" href={col.href[j]}>
                            {link}
                          </a>
                        </li>
                      ))}
              </ul>
            </div>
          ))}
          <div className="col footer__contact">
            <h4>{contact.header}</h4>
            <ul>
              <li>
                <img src={phone} alt="" />
                {contact.number}
              </li>
              <li>
                <img src={mail} alt="" />
                {contact.email}
              </li>
              <li>
                <a href={contact.supportLink} target="_blank">{contact.support}</a> 
              </li>
            </ul>
            <div id="google_translate_element"></div>
            <div className="location__currency">
              <select
                name="currency"
                id="nav__currency__footer"
                value={currency}
                onChange={handleCurrencyChange}
              >
                <option value="eur">Euro</option>
                <option value="usd">USD</option>
                <option value="GBP">GBP</option>
                <option value="CAD">CAD</option>
                <option value="AUD">AUD</option>
              </select>
            </div>
          </div>
        </div>
        <div className="pwd__reboost flex_center">
          <a target="_blank" href={"https://reboost.live/"}>
            <span>Powered by Reboost</span> <img src={reboost} alt="" />
          </a>
        </div>
      </div>
      <div className="bottom__section">
        <Link to={"/copyright"} target="_blank">
          <p>{text.copyright}</p>
        </Link>

        <div className="terms">
          <Link target="_blank" to={"/about-educify"}>
            <span>Educify</span>
          </Link>
          {"  |  "}
          <Link target="_blank" to={"/terms"}>
            <span>Terms and Conditions</span>
          </Link>
          {"  |  "}
          <Link target="_blank" to={"/privacy"}>
            <span>Privacy Policy</span>
          </Link>
        </div>
        <div className="socials">
          {socials.map((social, i) => (
            <a target="_blank" href={social.link} key={i}>
              <img src={social.image} alt="socials" />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
