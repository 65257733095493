import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";

const ChatBot = () => {
    const showChat = () => {
        // Check if dfMessenger is loaded before calling openChat
        if (window.dfMessenger && window.dfMessenger.openChat) {
            window.dfMessenger.openChat();
        } else {
            console.error("dfMessenger is not initialized yet.");
        }
    };

    return (
        <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
            {/* The custom Dialogflow web messenger */}
            <df-messenger
                intent="WELCOME"
                chat-title="Chat with Us!"
                agent-id="7d1323ad-973d-4546-bbe2-b836403619b3"  // Replace with your actual agent ID
                language-code="en"
            ></df-messenger>

            {/* Button to trigger openChat */}
            <button onClick={showChat} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faComments} style={{ width: '50px', height: '50px', color: '#007bff' }} />
            </button>
        </div>
    );
};

export default ChatBot;
