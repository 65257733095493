import React, { useEffect, useState } from "react";
import moment from "moment";
import { Tooltip } from "@mui/material";

import "./TeacherLanding.styles.scss";

// import TeacherCharts from "../TeacherCharts/TeacherCharts";
import Msg from "../../../assets/TeacherApp/assets/svgs/studentMsg.svg";
import default_image from "../../../assets/SharedApp/images/default.webp";

import camera from "../../../assets/TeacherApp/assets/svgs/camera.svg";
import google from "../../../assets/TeacherApp/assets/svgs/gmaps.svg";
import studentImg from "../../../assets/TeacherApp/assets/svgs/boy 1.svg";
// import studentCall from "../../../assets/TeacherApp/assets/svgs/studentcall.svg";

import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

import { useDispatch, useSelector } from "react-redux";
import {
  // finishTeacherLesson,
  // getTeacherAcceptedBookings,
  getTeacherAppointmentsByStatus,
  // getTeacherBookings,
  // getTeacherLessons,
  // getTeacherPrevBookings,
  handleLessonChange,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";

import edit from "../../../assets/AdminApp/svgs/edit.svg";
import {
  convertTimezoneDash,
  convertTimezoneFromDate3 as convertTimezoneFromDate,
} from "../../../SharedApp/Components/Timezone";
import { TimezoneSelector } from "../../../hooks";
import Pagination from "../../../Components/Pagination/Pagination";
import TeacherCalender from "../../../TeacherApp/Pages/TeacherCalendar/TeacherCalendar";

export default function TeacherLanding() {
  const dispatch = useDispatch();

  const { teacher } = useSelector((state) => state.teacher);
  const { user } = useSelector((state) => state.user);

  const [appointments, setAppointments] = useState([]);
  const [prevAppointments, setPrevAppointments] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(1);

  const [currentPagePrev, setCurrentPagePRev] = useState(1);
  const [totalDataPrev, setTotalDataPrev] = useState(1);
  const [pagePrev, setPagePrev] = useState(1);

  const [selectedPrev, setSelectedPrev] = useState({});
  const [infoPrev, setInfoPrev] = useState();
  const [busy, setBusy] = useState(false);

  const navigate = useNavigate();

  const getTEachers = () => {
    setBusy(true);
    dispatch(
      getTeacherAppointmentsByStatus({
        teacherId: teacher.id,
        status: "ACCEPTED",
        filters: {
          limit: 5,
          page: currentPage,
          orderBy: "asc",
        },
      })
    ).then((Res) => {
      if (Res?.payload?.data?.teacherAppointments?.length) {
        setAppointments(Res.payload.data.teacherAppointments);
        setTotalData(Res.payload.data.totalAppointments);
        setPage(Res.payload.data.numOfPages);
      }
    });
    setBusy(false);
  };

  const getTEachersPrev = () => {
    setBusy(true);
    dispatch(
      getTeacherAppointmentsByStatus({
        teacherId: teacher.id,
        status: "COMPLETED",
        filters: { limit: 5, page: currentPagePrev },
      })
    ).then((Res) => {
      if (Res?.payload?.data?.teacherAppointments?.length) {
        setTotalDataPrev(Res?.payload?.data?.totalAppointments);
        setInfoPrev(Res?.payload?.data?.teacherAppointments[0]);
        setSelectedPrev(
          Res?.payload?.data?.teacherAppointments[0]?.booking?.student
        );
        setPagePrev(Res.payload.data.numOfPages);
        setPrevAppointments(Res.payload.data.teacherAppointments);
      }
    });
    setBusy(false);
  };

  useEffect(() => {
    if (teacher.id) return getTEachers();
    else return;
  }, [teacher.id, currentPage]);

  useEffect(() => {
    if (teacher.id) return getTEachersPrev();
    else return;
  }, [teacher.id, currentPagePrev]);

  const steps = [
    {
      element: ".teacher__todo",
      title: "Todo",
      intro: `In the "Teacher To-Do" section, you'll find a helpful list of your upcoming lessons, making it easy for you to plan and prepare for your teaching schedule.`,
    },
  ];
  const location = useLocation();
  const [showGuide, setShowGuide] = useState(true);
  const handleEdit = (bookingId, booking) => {
    dispatch(
      handleLessonChange({
        name: "booking",
        value: booking,
      })
    );
    navigate(`/date/${bookingId}/`);
  };
  return busy ? (
    <div className="spinner" />
  ) : (
    <div className="teacher__landing">
      <div className="containers">
        {showGuide && location.pathname === "/teachers/myclasses" && (
          <Steps
            enabled={user.helpGuide}
            steps={steps}
            initialStep={0}
            onExit={() => setShowGuide(false)}
            options={{ exitOnOverlayClick: false }}
          />
        )}
        {appointments && appointments.length > 0 && (
          <div className="row">
            <TeacherDailySchedule
              bookings={appointments}
              title="Upcoming Appointments"
              page={page}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalData={totalData}
              handleEdit={handleEdit}
              dataRendered={appointments?.length ? appointments.length : 0}
            />
          </div>
        )}
        <div className="row row-col">
          <TeacherCalender />
          <Graphics />
        </div>
        {/* <TeacherCharts /> */}

        {prevAppointments && prevAppointments.length > 0 && (
          <div className="row">
            <TeacherDailySchedule
              bookings={prevAppointments}
              title="Lessons History"
              page={pagePrev}
              setCurrentPage={setCurrentPagePRev}
              currentPage={currentPagePrev}
              totalData={totalDataPrev}
              dataRendered={
                prevAppointments?.length ? prevAppointments.length : 0
              }
            />
            {selectedPrev?.id && (
              <StudentInfo
                hide
                fName={selectedPrev?.user.name}
                lName={selectedPrev?.user.lastName}
                date={infoPrev?.date}
                studentId={infoPrev?.booking?.student.id}
                startTime={infoPrev?.startTime}
                endTime={infoPrev?.endTime}
                teacherId={teacher?.id}
                bookingId={infoPrev?.id}
                booking={infoPrev?.booking}
                image={
                  selectedPrev.user?.profileImage?.url
                    ? selectedPrev.user.profileImage.url
                    : studentImg
                }
                lesson={infoPrev?.booking?.lesson?.title}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const TeacherDailySchedule = ({
  bookings,
  title,
  page,
  setCurrentPage,
  currentPage,
  totalData,
  dataRendered,
  handleEdit,
}) => {
  const headers =
    title !== "Lessons History"
      ? ["Students", "Lessons", "Venue", "Actions"]
      : ["Students", "Lessons", "Venue"];
  const timezone = useSelector((state) => state.user.timezone);
  const getLessonDuration = (date1, date2) => {
    return `${convertTimezoneDash(
      moment.utc(date1).format("HH:mm"),
      "UTC",
      timezone
    )} - ${convertTimezoneDash(
      moment.utc(date2).format("HH:mm"),
      "UTC",
      timezone
    )} `;
  };

  const viewLocation = (booking) => {
    const { street, city, state, country, zipCode } =
      booking.student?.user?.address;
    const address = `${street}, ${city}, ${state}, ${country}, ${zipCode}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(googleMapsUrl, "_blank");
  };
  const handleActionClick = (item) => {
    item.booking.location === "online"
      ? window.open(
        `/lessonRoom/${item?.teacher?.id}/${item?.booking?.studentId}/${item?.booking?.lesson?.title}`,
        "blank"
      )
      : viewLocation(item.booking);
  };
  const navigate = useNavigate();
  return (
    <div className="TeacherDailySchedule">
      <div className="flex_center">
        <h3>{title}</h3>
        <TimezoneSelector />
      </div>
      <div className="students__table">
        <div className="headers">
          {headers.map((el, i) => (
            <span key={i}>{el}</span>
          ))}
        </div>
        <div className="rows">
          {bookings.length &&
            bookings.map((item, index) => (
              <div className="student__row" key={index}>
                <div className="student__col">
                  <img
                    src={
                      item.booking?.student?.user?.profileImage?.url
                        ? item.booking?.student?.user?.profileImage.url
                        : default_image
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = default_image;
                    }}
                    alt=""
                  />
                  <div className="name">
                    <span>
                      {item.booking?.student?.user.name}{" "}
                      {item.booking?.student?.user.lastName}
                    </span>
                  </div>
                </div>
                <div className="student__col">
                  <div className="name">
                    <span>
                      {item.booking.lesson.title
                        ? item.booking.lesson.title
                        : "unknown lesson"}
                    </span>
                    <p>{getLessonDuration(item.startTime, item.endTime)}</p>{" "}
                    {/* here */}
                    {/* <p>{booking.date[0].split("T")[0]}</p> */}
                    <span>{moment(item.date).format("DD/MM/YYYY")}</span>
                  </div>
                </div>
                <div className="student__col">
                  <div className="name">
                    <span>{item.booking.location}</span>
                  </div>
                </div>

                {title !== "Lessons History" && (
                  <div className="student__col">
                    <div className="user_location_navigate">
                      <div className="name">
                        <button onClick={() => handleEdit(item.id, item)}>
                          Reschedule
                        </button>
                        {/* {item.location !== "teacherPlace" &&
                      (item.location === "online" ? (
                        <Tooltip title={"Start Online Lesson"} arrow>
                          <img
                            src={camera}
                            alt=""
                            onClick={() => handleActionClick(item)}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title={"Directions"} arrow>
                          <div className="googlemaps_icon flex_center">
                            <img
                              src={google}
                              alt=""
                              onClick={() => handleActionClick(item)}
                            />
                          </div>
                        </Tooltip>
                      ))} */}
                        {title === "Upcoming Appointments" && (
                          <button onClick={() => navigate("/teachers/roster")}>
                            Attendance
                          </button>
                        )}

                        {item.booking.location === "online" ? (
                          <Tooltip title="Start Online Lesson" arrow>
                            <button onClick={() => handleActionClick(item)}>
                              Start Lesson
                            </button>
                          </Tooltip>
                        ) : item.booking.location === "studentPlace" ? (
                          <Tooltip title="Directions" arrow>
                            <img src={google} alt="Directions" onClick={() => handleActionClick(item)} />
                          </Tooltip>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
      <Pagination
        page={page}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalData={totalData}
        dataRendered={dataRendered}
      />
    </div>
  );
};

const StudentInfo = ({
  image,
  fName,
  lName,
  date,
  bookingId,
  startTime,
  endTime,
  location,
  lesson,
  studentId,
  teacherId,
  hide,
  booking,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const timezone = useSelector((state) => state.user.timezone);

  const viewLocation = () => {
    const { street, city, state, country, zipCode } =
      booking.student?.user?.address;
    const address = `${street}, ${city}, ${state}, ${country}, ${zipCode}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleActionClick = () => {
    booking.location === "online"
      ? window.open(`/lessonRoom/${teacherId}/${studentId}/${lesson}`, "blank")
      : viewLocation();
  };

  const handleEdit = () => {
    dispatch(
      handleLessonChange({
        name: "booking",
        value: booking,
      })
    );
    navigate(`/date/${bookingId}/`);
  };

  const hourString = (dates) =>
    convertTimezoneDash(moment.utc(dates).format("HH:mm"), "UTC", timezone);

  return (
    <div className="student__lesson">
      <div className="student__lesson__details">
        <div className="info__row">
          <span>Date</span>
          <p>
            {convertTimezoneFromDate(
              moment.utc(date).format(),
              "UTC",
              timezone
            )}
          </p>
        </div>
        <div className="info__row">
          <span>Time</span>
          <p>
            {hourString(startTime)}-{hourString(endTime)}
          </p>
        </div>
        <div className="info__row">
          <span>Venue</span>
          <p>{booking.location}</p>
        </div>
      </div>
      <div className="student__details">
        <div className="std__image">
          <img
            src={image ? image : default_image}
            alt=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = default_image;
            }}
          />
        </div>

        <span>{fName + " " + lName}</span>
        <p>Student</p>
        {!hide && (
          <div className="student__contact">
            <Tooltip title="Reschedule" arrow>
              <img src={edit} alt="" onClick={() => handleEdit()} />
            </Tooltip>
            <Tooltip title="Contact" arrow>
              <img
                src={Msg}
                alt=""
                onClick={() => navigate("/teachers/mymessages")}
              />
            </Tooltip>


            {booking.location !== "teacherPlace" && (
              booking.location === "online" ? (
                <Tooltip title="Start Online Lesson" arrow>
                  <button onClick={() => handleActionClick()}>
                    Start Lesson
                  </button>
                </Tooltip>
              ) : (
                <Tooltip title="Directions" arrow>
                  <img src={google} alt="Directions" onClick={() => handleActionClick()} />
                </Tooltip>
              )
            )}
          </div>
        )}
        <button>{lesson}</button>
      </div>
    </div>
  );
};

const Graphics = () => {
  return (
    <div className="graphics">
      <div className="text">
        <span>You can manage your subjects and lessons</span>
      </div>
      <p className="description">by clicking on the button below</p>
      <Link to="/teachers/subjects/1" className="flex_center">
        My Listings
      </Link>
    </div>
  );
};
